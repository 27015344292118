import axios from 'axios'
import { env } from '../configs'

let definition = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  multipart: {
    'Content-Type': 'multipart/form-data'
  }
}

let _combineHeader = (headers) => {
  let obj = Object.assign(definition, {})
      obj.headers['Authorization'] = env.GITLAB_ACCESS_TOKEN
      obj.headers['PRIVATE-TOKEN'] = env.GITLAB_PRIVATE_TOKEN
      for (let prop in headers) {
        obj.headers[prop] = headers[prop]
      }
  return obj
}

let _generateParams = (obj) => {
  return Object.keys(obj).map((key) => {
    return key + '=' + encodeURIComponent(obj[key])
  }).join('&')
}

export default {
  install () {
    axios.defaults.baseURL = env.API_SERVER
    for (let name in definition.headers) {
      axios.defaults.headers.common[name] = definition.headers[name]
    }
    // In 30 second
    axios.defaults.timeout = 60000

    // Config Interceptors
    axios.interceptors.response.use((config) => {
      return config
    }, (error) => {
      return error.response
    })
  },
  post: obj => {
    return axios.post(obj.url, JSON.stringify(obj.data), _combineHeader(obj.headers))
  },
  get: obj => {
    return axios.get(obj.url + (obj.data ? ('?' + _generateParams(obj.data)) : ''), _combineHeader(obj.headers))
  },
  put: obj => {
    return axios.put(obj.url, JSON.stringify(obj.data), _combineHeader(obj.headers))
  },
  patch: obj => {
    return axios.patch(obj.url, JSON.stringify(obj.data), _combineHeader(obj.headers))
  },
  delete: obj => {
    return axios.delete(obj.url + (obj.data ? ('?' + _generateParams(obj.data)) : ''), _combineHeader(obj.headers))
  },
  post_data: obj => {
    return axios.post(obj.url, obj.data, _combineHeader(obj.headers))
  },
}
